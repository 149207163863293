import UIkit from 'uikit';

window.UIkit = UIkit;

ymaps.ready(init);

function init() {
    const myMap = new ymaps.Map("map", {
        center: [45.281398, 37.368052],
        zoom: 15,
        controls: []
    });

    const addressPoint = new ymaps.Placemark([45.281398, 37.368052],
        {
            // Контент метки.
            iconContent: 'CITYSTYLE',

        }, {
            preset: 'islands#blackStretchyIcon',
        })

    myMap.geoObjects.add(addressPoint);
}

const gallery = document.querySelector('.wp-block-gallery');
const mobileMenu = document.querySelector('#mobile-menu');

if (gallery) {
    gallery.setAttribute('uk-lightbox', '')
}

if (mobileMenu) {
    mobileMenu.addEventListener('click', () => {
        UIkit.offcanvas('#offcanvas-slide').hide();
    })
}